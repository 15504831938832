<template>
	<div class="m-login">
		<div class="m-login-form">
			<div class="m-login-left-top"></div>
			<div class="m-login-box">
				<p class="m-login-font1 u-m-b-60">在线学习系统</p>
				<el-form ref="form" :rules="rules" :model="form" label-width="0">
					<el-form-item prop="phone">
						<el-input v-model="form.phone" placeholder="手机号">
							<div class="m-img-icon" slot="suffix">
								<img src="../assets/img/common/input2.png" class="" />
							</div>
						</el-input>
					</el-form-item>
					<el-form-item prop="password"> 
						<el-input type="password" v-model="form.password" placeholder="密码">
							<div class="m-img-icon" slot="suffix">
								<img src="../assets/img/common/input1.png" style="height: 18px;width: 15px;" class="" />
							</div>
						</el-input>
					</el-form-item>
					<el-form-item>
						<div class="u-flex u-row-right m-login-font2">
							<span class="m-login-font2" @click="to_link('/forget')">忘记密码</span>
							<span class="m-login-font2 u-m-l-10 u-m-r-10">|</span>
							<span class="m-login-font2" @click="to_link('/register')">注册</span>
						</div>
					</el-form-item>
					<el-form-item>
						<el-button style="background: #437EFE;border: 0;width: 100%;height: 48px;" type="primary" @click="onSubmit">登录</el-button>
					</el-form-item>
				</el-form>
			</div>
			<div class="m-login-right-bottom"></div>
		</div>
	</div>
</template>

<script>
	import {to_link} from "@/router/api.js"
	import {login} from "@/api/common.js"
	export default {
		data() {
			var checkPhone = (rule, value, callback) => {
				if (!(/^1[3456789]\d{9}$/.test(value))) {
					callback(new Error('手机号不正确'));
				} else {
					callback()
				}
			}
			return {
				rules: {
					phone: [{
							required: true,
							message: '请输入手机号',
							trigger: ['blur', 'change']
						},
						{
							validator: checkPhone,
							trigger: ['blur', 'change']
						}
					],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: ['blur', 'change']
					}],
				},
				form: {
					phone:'',
					password:''
				}
			}
		},
		methods: {
			to_link,
			onSubmit() {
				this.$refs.form.validate(async (valid)=>{
					if(valid){
						let res = await login(this.form)
						if(res.code == 200){
							this.$message.success(res.message)
							localStorage.setItem('token',res.token)
							to_link('/')
						}else{
							this.$message.error(res.message)
						}
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.m-login {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: url(../assets/img/common/login-bg.png) no-repeat;
		background-size: cover;
		background-position: center;
		display: flex;
		justify-content: center;
		align-items: center;

		.m-login-form {
			display: flex;
			position: relative;
			width: 486px;
			height: 540px;
			background: rgba($color: #fff, $alpha: 0.65);
			border-radius: 20px;

			.m-login-font1 {
				text-align: center;
				font-size: 24px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #000000;
				line-height: 24px;
				margin-top: 100px;
			}
			
			.m-login-font2{
				font-size: 16px;
				color: #000;
				cursor: pointer;
			}

			.m-login-left-top {
				position: absolute;
				left: 0;
				top: 0;
				width: 97px;
				height: 92px;
				background: url(../assets/img/common/login-icon2.png);
				background-size: 100% 100%;
			}

			.m-login-right-bottom {
				position: absolute;
				right: 0;
				bottom: 0;
				width: 97px;
				height: 92px;
				background: url(../assets/img/common/login-icon1.png);
				background-size: 100% 100%;
			}

			.m-login-box {
				display: flex;
				flex-direction: column;
				width: 320px;
				margin: 0px auto;

				.m-img-icon {
					display: flex;
					align-items: center;
					height: 100%;

					img {
						width: 18px;
						height: 18px;
					}
				}
			}
		}
	}
</style>
